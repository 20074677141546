<template>
  <div class="login-section-outer row">
    <div class="col-lg-8 col-md-8 col-12 px-0">
      <div class="
          d-flex
          flex-column
          min-vh-100
          justify-content-center
          align-items-center
          custom-baner-logo-height-mb
        ">
        <img class="img-fluid" src="/assets/images/goa-banner.jpg" alt="partylogo" />
        <!-- <img v-if="this.client_info.maa59 == null || this.client_info.maa59 == ''" class="img-fluid" src="/assets/images/login-banner.jpg"
          alt="partylogo" />
        <img v-if="this.client_info.maa59 != null && this.client_info.maa59 != ''" class="img-fluid" :src="party_banner" alt="partylogo" /> -->
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-12">
      <div class="login-form-outer">
        <div class="login-form-inner">
          <div class="login-header-image text-center">
            <div v-if="onchangeuserlogo == ''">
              <img src="/assets/images/profile.svg" class="rounded-circle login-img-border" alt="userprofile" width="90"
                height="90" />
            </div>
            <div v-else>
              <img :src="this.onchangeuserlogo" class="rounded-circle login-img-border" alt="userprofile" width="90"
                height="90" />
            </div>
            <div class="login-header-sublabel" v-if="this.onchangeloginusername">{{ this.onchangeloginusername }}</div>
          </div>
          <div class="login-header-label">Forgot Password?</div>
          <div class="login-form-layout">
            <div class="row">
              <div class="col-12">
                <div class="custom-form-group">
                  <label for="formUsernameInput" class="form-label">Mobile No.<span class="text-danger">*</span></label>
                  <input type="text" v-model="username" class="form-control" id="formUsernameInput"
                    placeholder="Enter Your Registered Mobile No." autocomplete="off" @keypress="onlyNumberMobile"
                    maxlength="10" tabindex="1" />
                  <div class="custom-error" v-if="v$.username.$error">
                    {{ v$.username.$errors[0].$message }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group">
                  <button class="btn login-submit-btn" @click="ForgotPassword()" type="submit" tabindex="2">
                    Send Password
                  </button>
                </div>
              </div>
              <div class="col-12">
                <div class="custom-form-group d-flex justify-content-center">
                  <button class="nav-link custom-forgotpassword-label-btn" id="teamvoter-report-tab" data-bs-toggle="tab"
                    data-bs-target="#teamvoterreport" type="button" role="tab" aria-controls="customer-details"
                    aria-selected="false" @click="redirectloginurl()"><i class="pi pi-arrow-left me-2" style="font-size: 10px;"></i>Back to
                    Login</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="login-footer-outer">
          <div class="d-flex justify-content-between align-items-end login-footer-logo">
            <img src="/assets/images/goa-electronics.png" class="img-fluid" alt="goa electronics" width="150" />
            <img src="/assets/images/logo.png" class="img-fluid" alt="goa electronics" width="90" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      username: '',
      client_info: "",
      party_logo: "",
      baseUrl: window.location.origin,
      party_banner: "",
      onchangeuserlogo: "",
      onchangeloginusername: "",
      userloginList: [],
      client_path: '',
      localstorageimgpath: '',
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  watch: {
    username() {
      this.v$.$validate();
      if (this.username.length == 10) {
        this.getLoginUserByMobileNumber(this.username);
      } else {
        this.onchangeuserlogo = "";
        this.onchangeloginusername = '';
      }
    },
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage("Please enter mobile No.", required),
      },
    };
  },
  mounted() {
    var usernamemobile = localStorage.localusername;
    this.username = usernamemobile;
    this.localstorageimgpath = localStorage.localstoragepath;
    // this.client_info = JSON.parse(localStorage.client_info);
    // if (this.baseUrl == 'http://localhost:8080') {
    //   this.client_path = this.client_info.maa24;
    // } else {
    //   this.client_path = localStorage.masterbucketname;
    // }
    // this.party_logo = "https://storage.googleapis.com/" + this.client_path + "/Client/Logo/" + this.client_info.maa6;
    // // this.party_banner = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyBanner/" + this.client_info.maa59;
    // this.localstorageimgpath = this.client_info.maa24;
  },

  methods: {
    redirectloginurl() {
      this.$router.push("/login");
    },
    getLoginUserByMobileNumber(e) {
      this.ApiService.getLoginUserByMobileNumber({ login_id: e }).then((data) => {
        if (data.status == 200) {
          this.userloginList = data.data;
          if (this.userloginList.ad22 && this.userloginList.ad23 == 2 ) {
            this.onchangeuserlogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/host/userprofile/" + this.userloginList.ad22;
          } else if (this.userloginList.ad22 && this.userloginList.ad23 == 1 ) {
            this.onchangeuserlogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/admin/userprofile/" + this.userloginList.ad22;
          }else if (this.userloginList.ad22 && this.userloginList.ad23 == 3 ) {
            this.onchangeuserlogo = "https://storage.googleapis.com/" + this.localstorageimgpath + "/law/userprofile/" + this.userloginList.ad22;
          }else {
            this.onchangeuserlogo = '';
          }
          this.onchangeloginusername = this.userloginList.ad9;
        } else {
          this.errormsg = data.message;
          this.onchangeuserlogo = '';
          this.onchangeloginusername = '';
        }
      });
    },
    ForgotPassword() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.ApiService.forgotpassword({ mobile_no: this.username }).then(
          (data) => {
            if (data.status == 200) {
              if (data.data != "") {
                this.clientUserList = data.data;
              } else {

                this.username = "";
                var successMsg = data.message;
                this.$toast.open({
                  message: successMsg,
                  type: "success",
                  duration: 3000,
                  position: "top-right",
                });
                setTimeout(() => {
                  this.v$.$reset();
                }, 0);
                this.$router.push("/login");
              }
            } else {
              this.loading = false;
              this.clientUserList = null;
              var errorMsg = data.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          }
        );
      } else {
        this.$error;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("formUsernameInput");
      if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.custom-backtologin-btn {
  font-size: 13px;
  font-family: 'AcuminPro-Regular';
  letter-spacing: 0.26px;
  color: #5266E5;
  padding: 0;
  line-height: 13px;
}
</style>